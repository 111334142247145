import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';
import { ConnectionService } from '@services/connection-service';
import { DeliveryDateService } from '@services/delivery-date-service/delivery-date.service';
import { TimeService } from '@services/time-service';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);

@Component({
  selector: 'app-week-zero-journey',
  templateUrl: './week-zero-journey.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  standalone: false,
})
export class WeekZeroJourneyComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: {prevEl: 'nonext', nextEl: 'nonext'},
  };
  expectedDeliveryDate: { from?: Date, to?: Date } = {};
  @Input('user') user: any;
  @Input('latestPlacedRegimenOrder') latestPlacedRegimenOrder: any;
  @Output('trackOrder') trackOrder: EventEmitter<any> = new EventEmitter();
  @Input('allocatedDoctor') allocatedDoctor: any;
  @Input('userDrImage') userDrImage: string;
  @Input('defaultDoctorImage') defaultDoctorImage: string;
  @Input('primaryConcern') primaryConcern: string;
  @Input('otherConcerns') otherConcerns: string;
  @Output('openInstructions') openInstructions: EventEmitter<string> = new EventEmitter();
  @Output('openDiet') openDiet: EventEmitter<string> = new EventEmitter();
  @Output('openHelp') openHelp: EventEmitter<string> = new EventEmitter();
  deliveryIntervalInDays: number = 2;
  kitImg: string = '';
  orderId: string;
  order: any;

  constructor(public conn: ConnectionService,
              public timeService: TimeService,
              public deliveryDateService: DeliveryDateService) {
  }

  async ngOnInit(): Promise<void> {
    this.orderId = this.latestPlacedRegimenOrder?.id;
    this.order = await this.conn.findOrderWithSignedURL(this.orderId);
    await this.setDeliveryDate();
  }

  ngOnChanges(): void {
    this.kitImg = (this.latestPlacedRegimenOrder?.get('regimen')?.get('regimenCoverImage'));
  }

  async setDeliveryDate(): Promise<void> {
    try {
      const res = await this.conn.getExpectedDeliveryDateByOrderId(this.orderId);
      const responseDateISO = res?.expectedDeliveryDate;
      this.expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(
        responseDateISO,
        this.deliveryIntervalInDays,
      );
    } catch (error) {
      this.expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(null, this.deliveryIntervalInDays);
    }
  }

  handleOrder(url: string): void {
    this.trackOrder.emit(url);
  }

  handleInstructions(card: string): void {
    this.openInstructions.emit(card);
  }

  handleDiet(dietCard: string): void {
    this.openDiet.emit(dietCard);
  }

  handleHelp(helpCard: string): void {
    this.openHelp.emit(helpCard);
  }
}
