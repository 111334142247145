import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoadingModule } from '@components/loading';
import { CommonModule, Location } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { AppConfig } from 'client/app/app.config';
import { extractRegimenProducts, setRegimenPricing } from 'client/helper/regimen-helper';
import { extractAssetsForSlider, extractInstructionVideo } from 'client/helper/product-helper';
import { ApiClientConstant, RequestQueryPayload } from '@cureskin/api-client';
import { ButtonModule } from '@components/button';

import { CsIconModule } from '@components/icon';
import { ProductSlideShowComponent } from '../components/product-slide-show/product-slide-show.component';
import { VideoDialogComponent } from '../components/video-dialog/video-dialog.component';
import { UserRegimenModule } from '../user-regimen.module';
import { DOCTOR_NOTE_MESSAGES, PRIVATE_MAIN_CONCERN_DISPLAY_NAME, TREATMENT_MAPPING } from './doctor-notes-constants';

const regimenFieldIncldue: any = [
  'morning.product' as 'morning',
  'night.product' as 'night',
  'morning.product.productHighlights',
  'night.product.productHighlights',
];

@Component({
  selector: 'app-regimen-products-detail',
  imports: [CommonModule, ButtonModule, CsIconModule, LoadingModule, SwiperModule, DialogModule, UserRegimenModule],
  templateUrl: './regimen-products-detail.component.html',
  styles: `:host {
    @apply tw-block tw-font-body tw-pt-20 tw-font-normal tw-bg-gray-80 tw-h-full tw-overflow-y-auto tw-relative;
    background: linear-gradient(180deg, rgba(244,255,252,1) 0%, rgba(244,255,252,1) 46%, rgba(0,212,255,0) 100%), #F3F3F3;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
  }`,
  standalone: true
})
export class RegimenProductsDetailComponent implements OnInit {
  eventLoggerService: EventLoggerService = inject(EventLoggerService);
  connectionService: ConnectionService = inject(ConnectionService);
  route: ActivatedRoute = inject(ActivatedRoute);
  appConfig: AppConfig = inject(AppConfig);
  location: Location = inject(Location);
  router: Router = inject(Router);
  dialog: Dialog = inject(Dialog);
  isLoading: boolean = false;
  isShowHighlight: boolean;
  isInternalUser: boolean;
  allocatedDoctor: any;
  formattedDate: { date: number, month: string, time: string };
  products: any = [];
  regimen: any;
  user: any;
  doctorNoteBannerExperiment: boolean = false;
  faceConsultationSession: any;
  username: string;
  doctorNoteMessage: string;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1.75,
    autoHeight: true,
    pagination: false,
    navigation: false,
    autoplay: false,
  };

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const regimenClass = this.route.snapshot.queryParams.class;
    const { regimenId }: any = this.route.snapshot.queryParams;
    this.user = await this.connectionService.getActingUser();
    this.username = this.user?.get('PatientName')?.split(' ')[0];
    this.isInternalUser = this.connectionService.isInternalUser();

    await this.fetchExperiments()

    const regimens: any[] = (await this.connectionService.fetchRegimens(regimenId, true, true, false, regimenFieldIncldue));
    this.regimen = regimens?.find((each: any): boolean => each.class === regimenClass);
    this.regimen = setRegimenPricing(this.regimen);

    const payload: RequestQueryPayload<Table.ConsultationSession> = {
      where: {
        user: this.user,
        PrivateMainConcern: { $exists: true },
        PrivateMainConcernClass: { $in: this.appConfig.Shared.Regimen.Class.FACE },
      },
      descending: 'createdAt',
    };
    const consultationSessions = await this.connectionService.fetchConsultationSessions(payload);
    this.faceConsultationSession = consultationSessions[0];

    if (this.doctorNoteBannerExperiment && this.regimen?.class === this.appConfig.Shared.Regimen.Class.FACE) {
      this.setDoctorNoteMessage();
    }
    this.formattedDate = this.getFormattedDate(this.regimen?.createdAt);
    this.products = extractRegimenProducts(this.regimen)
      .map((product: any): any => {
        const images = extractAssetsForSlider(product.product);
        const video = extractInstructionVideo(product, this.user);
        return { ...product, images, video };
      });
    if (this.user?.get('allocatedDoctor')) {
      this.allocatedDoctor = await this.connectionService.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }

    // Log page open event
    this.eventLoggerService.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-view-unpaid' }));
    this.isLoading = false;
  }

  async fetchExperiments(): Promise<any> {
    const experiments = await this.connectionService.findUserActiveExperiments();
    for (const experiment of experiments) {
      if (experiment.key === 'show_regimen_product_highlight'
        && experiment?.variant?.languages?.includes(this.user.get('languagePreference'))) {
        this.isShowHighlight = true;
      }
      if (experiment.key === 'regimen_details_doctor_note_banner') this.doctorNoteBannerExperiment = true
    }
  }

  setDoctorNoteMessage(): void {
    const language = this.user.get('languagePreference') === this.appConfig.Shared.Languages.HI
      ? this.appConfig.Shared.Languages.HI
      : this.appConfig.Shared.Languages.EN;
    const messages = DOCTOR_NOTE_MESSAGES[language];

    // Level 1 – Treatment Change:
    const privateConcern = this.faceConsultationSession?.get('PrivateMainConcern');
    if (
      privateConcern &&
      privateConcern !== this.appConfig.Shared.String.NO_CONCERN &&
      !TREATMENT_MAPPING[privateConcern]?.includes(this.regimen.displayConcern.name.toLowerCase())
    ) {
      const displayPrivateConcern = PRIVATE_MAIN_CONCERN_DISPLAY_NAME[language][privateConcern] ?? privateConcern;
      this.doctorNoteMessage = messages.treatmentChange(this.regimen.displayConcern.name ?? this.regimen.concern, displayPrivateConcern);
      this.eventLoggerService.cleverTapEvent(
        'regimen_doctor_note_shown',
        JSON.stringify({ name: 'Treatment_change' })
      );
      return;
    }

    // Level 2 – PCOD / PCOS:
    const femaleHormonal = this.faceConsultationSession?.get('FemaleHormonal');
    if (femaleHormonal && (femaleHormonal === "I was diagnosed with PCOD")) {
      this.doctorNoteMessage = messages.pcod;
      this.eventLoggerService.cleverTapEvent(
        'regimen_doctor_note_shown',
        JSON.stringify({ name: 'PCOD' })
      );
      return;
    }

    // Level 3 – Sensitive Skin:
    const isSensitiveSkin = this.faceConsultationSession?.get('SensitiveSkin');
    if (isSensitiveSkin === this.appConfig.Shared.String.SENSITIVE_SKIN) {
      this.doctorNoteMessage = messages.sensitiveSkin;
      this.eventLoggerService.cleverTapEvent(
        'regimen_doctor_note_shown',
        JSON.stringify({ name: 'Sensitive' })
      );
      return;
    }

    // Level 4 – Dryness:
    const concernList = this.faceConsultationSession?.get('concernList');
    if (Array.isArray(concernList) && concernList.includes("DRY_SKIN_FACE")) {
      this.doctorNoteMessage = messages.dryness;
      this.eventLoggerService.cleverTapEvent(
        'regimen_doctor_note_shown',
        JSON.stringify({ name: 'Dryness' })
      );
      return;
    }

    // Level 5 – Based on Skin Concern:
    if (privateConcern && messages.skinConcern[privateConcern]) {
      this.doctorNoteMessage = messages.skinConcern[privateConcern];
      this.eventLoggerService.cleverTapEvent(
        'regimen_doctor_note_shown',
        JSON.stringify({ name: privateConcern })
      );
    }
  }

  doctorCardClick(): void {
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_dr_card_clicked' }));
  }

  openSlideShow(product: any): void {
    this.dialog.open(ProductSlideShowComponent, { data: { product }, backdropClass: ['tw-opacity-90', 'tw-bg-black'] });
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_product_image_clicked' }));
  }

  openVideoPlayer(url: string): void {
    this.dialog.open(VideoDialogComponent, { data: { url }, backdropClass: ['tw-opacity-90', 'tw-bg-black'] });
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_play_video_clicked' }));
  }

  buyRegimen(): void {
    this.eventLoggerService.trackInParse('BUY_NOW', ApiClientConstant?.Event?.Type?.ORDER);
    this.eventLoggerService.trackEvent('BUY_NOW_CLICKED');
    this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLoggerService.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.router.navigate(['/user/checkout'],
      { queryParams: { type: 'REGIMEN', regimenId: this.regimen.regimenId }, replaceUrl: true });
  }

  async readInstructions(): Promise<void> {
    this.eventLoggerService.trackEvent('REGIMEN_VIEW_READ_INSTRUCTION', { username: this.user?.get('username') });
    await this.router.navigate(['/user'],
      {
        queryParams: {
          tab: 'regimen',
          class: this.regimen?.class,
          instructions: true,
        },
      });
  }

  getFormattedDate(isoString: string): { date: number, month: string, time: string } {
    const dateObj = new Date(isoString);
    dateObj.setMinutes(dateObj.getMinutes() + 2);

    const date: number = dateObj.getDate();
    const month: string = dateObj.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });

    let hours: number = dateObj.getHours();
    const minutes: number = dateObj.getMinutes();

    const ampm: string = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    const time: string = `${hours}:${minutes.toString().padStart(2, '0')}${ampm}`;

    return { date, month, time };
  }

  getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  back(): void {
    this.location.back();
  }
}
