import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { LoadingModule } from '@components/loading';
import { TestimonialModule } from '../testimonial/testimonial.module';
import { ReportComponent } from './report.component';
import { SkinParameterModule } from './components/skin-parameter/skin-parameter.module';
import { TreatmentDimensionModule } from './components/treatment-dimension/treatment-dimension.module';
import { ConcernAccordionModule } from './components/concern-accordion/concern-accordion.module';
import { ImprovementJourneyModule } from './components/improvement-journey/improvement-journey.module';
import { HeroIngredientComponent } from './components/hero-ingredients/hero-ingredients.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
    SkinParameterModule,
    TreatmentDimensionModule,
    ConcernAccordionModule,
    ConcernAccordionModule,
    CustomPipeModule,
    LoadingModule,
    TestimonialModule,
    ImprovementJourneyModule,
    HeroIngredientComponent,
  ],
  declarations: [ReportComponent],
  exports: [ReportComponent],
})
export class ReportModule { }
