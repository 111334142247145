import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { CsIconModule } from "@components/icon";

@Component({
  selector: 'hero-ingredients',
  templateUrl: './hero-ingredients.html',
  standalone: true,
  imports: [CommonModule, CsIconModule],
  styles: [`
    :host {
      @apply tw-block tw-mb-6;
    }
  `]
})
export class HeroIngredientComponent {
  @Input() heroIngredients: any[] = [];
  @Input() doctorDisplayImage: string = '';
}
