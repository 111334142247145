<ng-container *ngIf="hairMultiConcernUI; then hairMultiConcernTemplate else multiConcernTemplate"></ng-container>

<ng-template #multiConcernTemplate>
  <!-- Multi-concern template -->
  <div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200">
    <!-- Header section -->
    <ng-container *ngIf="showSecondaryConcerns; then secondaryConcernsHeader else primaryConcernsHeader"></ng-container>
    <!-- Primary concerns header -->
    <ng-template #primaryConcernsHeader>
      <div class="tw-relative">
        <div id="backButton"
          class="tw-ml-6 tw-mt-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl"
          (click)="!ui.saveInProcess && back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <h1 class="tw-font-body tw-font-bold tw-text-700 tw-text-gray-800 tw-mt-8 tw-mb-6 tw-ml-6 tw-max-w-[55%]"
          i18n="@@chooseYourMostImportantConcern">Choose your most important concern</h1>
        <img class="tw-absolute tw-h-[200px] tw-right-[-16px] tw-bottom-[-40px]" src="/assets/images/dr-charu.png"
          alt="Dr Charu" />
      </div>
    </ng-template>
    <!-- Secondary concerns header -->
    <ng-template #secondaryConcernsHeader>
      <div class="tw-relative tw-min-h-12 tw-max-h-12 tw-h-12">
        <div id="backButton"
          class="tw-absolute tw-ml-6 tw-mt-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-z-20"
          (click)="!ui.saveInProcess && handleBackToPrimaryConcerns()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
        </div>
      </div>
    </ng-template>
    <!-- Body section -->
    <div
      class="tw-grid tw-grid-rows-[1fr_auto] tw-h-full tw-bg-white tw-px-6 tw-pt-6 tw-pb-4 t tw-rounded-tr-5 tw-rounded-tl-5 tw-z-10 tw-overflow-hidden"
      [ngClass]='{"tw-grid-rows-[auto_1fr_auto]": showSecondaryConcerns}'>
      <ng-container *ngIf="ui.loading; then loadingTemplate else concernsSelectionTemplate"></ng-container>
      <!-- Loader -->
      <ng-template #loadingTemplate>
        <loading class="tw-justify-center tw-items-start"></loading>
      </ng-template>
      <!-- Concerns selection -->
      <ng-template #concernsSelectionTemplate>
        <!-- Switch between primary concerns list and secondary concrens list -->
        <ng-container
          *ngIf="showSecondaryConcerns; then secondaryConcernsContent else primaryConcernsContent"></ng-container>
        <!-- Primary concerns list -->
        <ng-template #primaryConcernsContent>
          <div class="tw-overflow-x-hidden tw-overflow-y-auto">
            <ul class="tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto">
              <li class="tw-flex tw-gap-2" *ngFor="let item of concernSections.TREATMENT; let i = index;"
                (click)="handleAddPrimaryConcern(item)">
                <div *ngIf="!selectedConcerns.includes(item.key)"
                  class="tw-w-5 tw-h-5 tw-m-[6px] tw-bg-gray-50 tw-border-2 tw-border-solid tw-border-gray-400 tw-rounded-[50%] tw-shrink-0">
                </div>
                <div *ngIf="selectedConcerns.includes(item.key)" class="tw-w-5 tw-h-5 tw-m-[6px] tw-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <rect width="20" height="20" rx="10" fill="#0E796C" />
                    <path
                      d="M12.9548 6.46302L8.9625 10.6128L6.85387 8.89778L5.49316 10.5734L8.37221 12.9126L9.14263 13.5368L9.8287 12.8226L14.507 7.9643L12.9551 6.46289L12.9548 6.46302Z"
                      fill="white" />
                  </svg>
                </div>
                <div class="tw-flex tw-flex-col">
                  <span
                    class="tw-font-body tw-text-400 tw-leading-[29px] tw-text-gray-800 tw-font-semibold">{{item.key}}</span>
                  <span class="tw-font-body tw-text-200 tw-text-gray-400 tw-font-regular">{{item.description || ''}}</span>
                </div>
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1 tw-bg-gray-50 tw-mt-auto tw-pt-2">
            <cs-button
              [class]="'tw-w-full primary ' + (selectedConcerns.length && selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN' ? 'tw-bg-teal-600' : 'tw-bg-gray-300')"
              [loading]="ui.saveInProcess && selectedConcerns.length && selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN'"
              [disabled]="ui.saveInProcess || !selectedConcerns.length"
              (callback)="handleShowSecondaryConcernsScreen()">
              <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50"
                i18n="@@next">Next</cs-button-text>
            </cs-button>
            <cs-button [class]="'tw-w-full secondary tw-border-none'"
              [loading]="ui.saveInProcess && selectedConcerns.length && selectedConcernsDetails?.[0]?.value === 'NO_CONCERN'"
              [disabled]="ui.saveInProcess || !!selectedConcerns.length" (callback)="handleSkipPrimaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-400 tw-font-bold"
                [ngClass]="{'tw-text-gray-400': selectedConcerns.length, 'tw-text-teal-600': !selectedConcerns.length}"
                i18n="@@iDontHaveAnyConcern">I don't have any concern</cs-button-text>
            </cs-button>
          </div>
        </ng-template>
        <!-- Secondary concerns list -->
        <ng-template #secondaryConcernsContent>
          <div class="tw-mt-6 tw-mb-8">
            <h1 class="tw-font-body tw-font-bold tw-text-700 tw-text-gray-800" i18n="@@doYouHaveAnyOtherSkinConcerns">Do
              you have any other skin concerns?</h1>
            <span class="tw-inline-block tw-text-300 tw-text-gray-800 tw-mt-1" i18n="@@selectUpto3">(Select upto
              3)</span>
          </div>
          <div *ngIf="selectedConcernsDetails?.[0]?.concernsList?.length"
            class="tw-overflow-x-hidden tw-overflow-y-auto">
            <ul class="tw-flex tw-flex-wrap tw-gap-4 tw-overflow-y-auto">
              <li
                class="tw-box-border tw-flex tw-justify-center tw-items-center tw-gap-2 tw-px-4 tw-py-[9px] tw-border tw-border-solid tw-border-gray-400 tw-rounded-6"
                [ngClass]='{"tw-opacity-30": !selectedSecondaryConcerns[item.displayName] && isOpacityConditionMet, "tw-bg-teal-100 tw-border-teal-400": selectedSecondaryConcerns[item.displayName]}'
                *ngFor="let item of selectedConcernsDetails[0].concernsList; let i = index;"
                (click)="addRemoveSecondaryConcern(item)">
                <div *ngIf="!selectedSecondaryConcerns[item.displayName]"
                  class="tw-w-4 tw-h-4 tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-400 tw-rounded-[50%] tw-shrink-0">
                </div>
                <div *ngIf="selectedSecondaryConcerns[item.displayName]" class="tw-w-4 tw-h-4 tw-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#4EAE9C" />
                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#4EAE9C" />
                    <path
                      d="M11.4229 3.90249L6.79805 8.70975L4.35534 6.72301L2.77905 8.66408L6.11424 11.3739L7.00672 12.097L7.80149 11.2696L13.221 5.64163L11.4232 3.90234L11.4229 3.90249Z"
                      fill="white" />
                  </svg>
                </div>
                <span class="tw-font-body tw-text-300 tw-text-gray-800">{{item.displayName}}</span>
              </li>
            </ul>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1 tw-bg-gray-50 tw-mt-auto tw-pt-2">
            <cs-button
              [class]="'tw-w-full primary ' + (isSecondaryConditionSelected ? 'tw-bg-teal-600' : 'tw-bg-gray-300')"
              [disabled]="!isSecondaryConditionSelected || ui.saveInProcess"
              [loading]="ui.saveInProcess && isSecondaryConditionSelected"
              (callback)="handleContinueSecondaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50"
                i18n="@@continue">Continue</cs-button-text>
            </cs-button>
            <cs-button [class]="'tw-w-full secondary tw-border-none'" [disabled]="ui.saveInProcess"
              [loading]="ui.saveInProcess && !isSecondaryConditionSelected" (callback)="handleSkipSecondaryConcern()">
              <cs-button-text class="tw-normal-case tw-text-400 tw-font-bold tw-text-teal-600"
                i18n="@@skip">Skip</cs-button-text>
            </cs-button>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #hairMultiConcernTemplate>
  <div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200">
    <div class="tw-relative">
      <div id="backButton"
           class="tw-ml-6 tw-mt-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl"
           (click)="!ui.saveInProcess && back()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
      </div>
      <h1 class="tw-font-body tw-font-bold tw-text-700 tw-text-gray-800 tw-mt-8 tw-mb-6 tw-ml-6 tw-max-w-[55%]"
          i18n="@@chooseYourMostImportantConcern">Choose your most important concern</h1>
      <img class="tw-absolute tw-h-[200px] tw-right-[-16px] tw-bottom-[-40px]" src="/assets/images/dr-charu.png"
           alt="Dr Charu" />
    </div>
    <div
      class="tw-grid tw-grid-rows-[1fr_auto] tw-h-full tw-bg-white tw-px-6 tw-pt-6 tw-pb-4 t tw-rounded-tr-5 tw-rounded-tl-5 tw-z-10 tw-overflow-hidden"
      [ngClass]='{"tw-grid-rows-[auto_1fr_auto]": showSecondaryConcerns}'>
      <loading *ngIf="ui.loading" class="tw-justify-center tw-items-start"></loading>
      <div *ngIf="!ui.loading" class="tw-overflow-x-hidden tw-overflow-y-auto">
        <ul class="tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto">
          <li class="tw-flex tw-gap-2" *ngFor="let item of concernSections.TREATMENT; let i = index;"
              (click)="handleAddPrimaryConcern(item)">
            <div *ngIf="!selectedConcerns.includes(item.key)"
                 class="tw-w-5 tw-h-5 tw-m-[6px] tw-bg-gray-50 tw-border-2 tw-border-solid tw-border-gray-400 tw-rounded-[50%] tw-shrink-0">
            </div>
            <div *ngIf="selectedConcerns.includes(item.key)" class="tw-w-5 tw-h-5 tw-m-[6px] tw-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <rect width="20" height="20" rx="10" fill="#0E796C" />
                <path
                  d="M12.9548 6.46302L8.9625 10.6128L6.85387 8.89778L5.49316 10.5734L8.37221 12.9126L9.14263 13.5368L9.8287 12.8226L14.507 7.9643L12.9551 6.46289L12.9548 6.46302Z"
                  fill="white" />
              </svg>
            </div>
            <div class="tw-flex tw-flex-col">
              <span
                class="tw-font-body tw-text-400 tw-leading-[29px] tw-text-gray-800 tw-font-semibold tw-capitalize">{{item.key}}</span>
              <span class="tw-font-body tw-text-200 tw-text-gray-400 tw-font-regular">{{item.description || ''}}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-1 tw-bg-gray-50 tw-mt-auto tw-pt-2">
        <cs-button
          [class]="'tw-w-full primary ' + (selectedConcerns.length && (selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN' && selectedConcernsDetails?.[0]?.value !== 'REGULAR_HAIR_CARE') ? 'tw-bg-teal-600' : 'tw-bg-gray-300')"
          [loading]="ui.saveInProcess && selectedConcerns.length && (selectedConcernsDetails?.[0]?.value !== 'NO_CONCERN' && selectedConcernsDetails?.[0]?.value !== 'REGULAR_HAIR_CARE')"
          [disabled]="ui.saveInProcess || !selectedConcerns.length"
          (callback)="handleShowSecondaryConcernsScreen()">
          <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50"
                          i18n="@@next">Next</cs-button-text>
        </cs-button>
        <cs-button [class]="'tw-w-full secondary tw-border-none'"
                   [loading]="ui.saveInProcess && selectedConcerns.length && (selectedConcernsDetails?.[0]?.value === 'NO_CONCERN' || selectedConcernsDetails?.[0]?.value === 'REGULAR_HAIR_CARE')"
                   [disabled]="ui.saveInProcess || !!selectedConcerns.length" (callback)="handleSkipPrimaryConcern()">
          <cs-button-text class="tw-normal-case tw-text-400 tw-font-bold"
                          [ngClass]="{'tw-text-gray-400': selectedConcerns.length, 'tw-text-teal-600': !selectedConcerns.length}"
                          i18n="@@iDontHaveAnyConcern">I don't have any concern</cs-button-text>
        </cs-button>
      </div>
    </div>
  </div>
</ng-template>
