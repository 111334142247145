import { Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { TimeService } from '@services/time-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { RazorpayService } from '@services/razorpay-service';
import { AddressFormComponent } from '@shared/address-form/address-form.component';
import { AddressBookI } from '@store/modals';
import { fromActions } from '@store/actions';
import { fromSelectors } from '@store/selectors';
import { DeliveryDateService } from '@services/delivery-date-service/delivery-date.service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'user-checkout',
  templateUrl: './user-checkout.html',
  styleUrls: ['./user-checkout.scss'],
  standalone: false,
})
export class UserCheckoutComponent implements OnDestroy {
  ui: any;
  user: any;
  regimenId: any;
  typeOfOrder: any;
  products: any;
  messageId: any;
  expectedDeliveryDate: { from?: Date, to?: Date } = {};
  deliveryIntervalInDays: number = 2;
  services: any;
  offerTimer: any = {};
  subscriptions: Subscription[] = [];
  @ViewChild(AddressFormComponent) addressFormComponent: AddressFormComponent;
  defaultAddress$: Observable<AddressBookI> = this.store.select(fromSelectors.selectDefaultAddress);
  checkoutInProgress$: Observable<boolean> = this.store.select(fromSelectors.selectCheckoutProgress);
  pincode: number;
  orderDeliveryDateToCurrentDateDifference: number;
  deliveryDateCommunicationExperiment: boolean = false;
  newRegimenPricingExperiment: any;

  constructor(public conn: ConnectionService,
    public router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private windowRef: WindowRefService,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private timeService: TimeService,
    private zone: NgZone,
    private appBridge: AppWebBridgeService,
    public store: Store,
    private readonly razorpayService: RazorpayService,
    public deliveryDateService: DeliveryDateService) {
    this.razorpayService.initialize();
  }

  async ngOnInit(): Promise<any> {
    this.ui = { loading: true };
    await this.conn.updateLocalUser();
    this.user = this.conn.getActingUser();
    const isEnglishUser = this.user.get('languagePreference') === this.appConfig.Shared.Languages.EN;
    const params = this.route.snapshot.queryParams;
    const experiments = await this.conn.findUserActiveExperiments();
    experiments?.forEach((experiment: any): void => {
      if (experiment.key === 'tat_based_delivery_message' && isEnglishUser) {
        this.deliveryDateCommunicationExperiment = true;
      }
      if (experiment.key === 'add_extra_regimen_products') {
        this.newRegimenPricingExperiment = experiment;
      }
    });
    this.regimenId = params.regimenId;
    this.typeOfOrder = params.type;
    this.products = params.products;
    this.messageId = params.messageId;
    this.services = params.services;
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ regimenId: this.regimenId, pageName: 'address' }));
    await this.processOrder();
    this.defaultAddress$.subscribe(async (address: AddressBookI): Promise<void> => {
      this.pincode = address?.zipCode;
      if (this.pincode) await this.setDeliveryDate();
    });
    this.ui.loading = false;
  }

  /**
   * 1. Checks for offer timer experiment & takes the data from variant and pass it to discount-timer component.
   * 2. Consultation type orders doesn't need address so checks out by default.
   * 3. Fetches the default address from address book, if its empty, then redirects to address book creation page in effects.
   */
  async processOrder(): Promise<void> {
    this.sendOrderLogs();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments?.forEach((experiment: any): void => {
      if (experiment.key === 'offer_timer_throughout_checkout') {
        const timeLeft: any = (new Date(Number(experiment.variant.time)).getTime() - new Date().getTime());
        if (timeLeft > 0) {
          this.offerTimer = { endTime: timeLeft,
            title: experiment.variant.title[this.user?.get('languagePreference') || this.appConfig?.Shared?.Languages?.EN] };
        }
      }
    });
    this.store.dispatch(fromActions.AddressBookDefaultFetchBegin());
  }

  async setDeliveryDate(): Promise<void> {
    try {
      const res = await this.conn.getExpectedDeliveryDateByDestinationPinCode(this.pincode.toString());
      const responseDateISO = res?.expectedDeliveryDate;
      const todayDate = new Date();
      this.orderDeliveryDateToCurrentDateDifference = this.deliveryDateService.getDeliveryDateDifference(responseDateISO, todayDate);
      this.expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(
        responseDateISO,
        this.deliveryIntervalInDays,
      );
    } catch (error) {
      this.expectedDeliveryDate = this.deliveryDateService.calculateExpectedDeliveryDate(null, this.deliveryIntervalInDays);
    }
  }

  private sendOrderLogs(): void {
    this.eventLogger.trackEvent('checkout_entry', { username: this.user?.get('username') });
    this.eventLogger.trackInElasticSearch({ username: this.user?.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User into checkout page',
      event: 'CHECKOUT_ENTRY' });
  }

  async checkout(replaceUrl?: boolean): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'use-this-address' }));
    this.appBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'InitialOrder' },
      firebase: { name: 'InitialOrder' },
    });
    const payload: any = {
      userObjectId: this.user?.id,
      paymentType: this.appConfig.Shared.Order.PaymentType.NOT_SELECTED,
      type: this.typeOfOrder,
      regimenId: this.regimenId,
      products: this.products,
      messageId: this.messageId,
      uniqueId: uuid(),
      services: this.services,
    };

    if (this.newRegimenPricingExperiment && this.regimenId) {
      const extraProductIds = this.newRegimenPricingExperiment?.variant?.availableProducts || [];
      await this.conn.updateRegimenPrice({ regimenId: this.regimenId, extraProductIds });
    }

    this.store.dispatch(fromActions.CheckoutOrderBegin({ payload, replaceUrl }));
  }

  /**
   * Generate one line delivery address by appending all fields.
   */
  getDeliveryAddress(defaultAddress: AddressBookI): string {
    const address = [];
    address.push(defaultAddress.buildingDetails);
    address.push(defaultAddress.localityDetails);
    address.push(defaultAddress.city);
    address.push(defaultAddress.state);
    address.push(defaultAddress.zipCode);
    return address.filter((each: string): boolean => !!each).join(', ');
  }

  navigateTo(url: string): void {
    let queryParams;
    if (this.regimenId) {
      queryParams = { regimenId: this.regimenId };
    }
    if (this.products) {
      queryParams = { products: this.products };
    }
    // eslint-disable-next-line no-unused-expressions
    queryParams ? this.router.navigate([url], { queryParams }) : this.router.navigate([url]);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
  }
}
