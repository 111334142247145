
export const DOCTOR_NOTE_MESSAGES = {
  en: {
    pregnancy: {
      "Trying for pregnancy": `your skincare is <b>safe while you're trying for pregnancy!</b> Remember to stay hydrated, eat nourishing foods, and reduce dairy & sugar for a healthy skin...`,
      "I am pregnant": `your skincare routine is <b>completely safe for pregnancy!</b> Remember to stay hydrated, eat nourishing foods, and reduce dairy & sugar for a healthy skin...`,
      "I am a new mom and breastfeeding": `your skincare routine is <b>safe while breastfeeding!</b> Remember to stay hydrated, eat nourishing foods, and reduce dairy & sugar for a healthy skin...`,
    },
    treatmentChange: (displayConcern: string, privateConcern: string) =>
      `your <b>${displayConcern} needs treatment first</b> before we address ${privateConcern}. Follow the routine, stay hydrated and limit dairy & sugar for best results...`,
    pcod: `this skincare is <b>especially designed with your PCOD in mind</b> and is completely safe for you. Stay hydrated and follow the routine consistently for best results...`,
    sensitiveSkin: `We’ve chosen products <b>safe for your sensitive skin</b>. Try to <b>avoid triggers</b> like spicy food and hot beverages, as they can worsen sensitivity...`,
    dryness: `<b>to combat your skin dryness</b>, stay hydrated, eat omega-3s and use a humidifier if needed. Also, be gentle on your skin - skip harsh scrubs & alcohol-based toners!`,
    skinConcern: {
      "ACNE_OR_PIMPLES": `<b>avoid picking or squeezing acne</b> to prevent scars. Also, drink plenty water and reduce dairy and sugar intake for best results...`,
      "DARK_SPOTS_OR_MARK": `sunscreen can <b>prevent dark spots</b> from getting darker. So, <b>use sunscreen daily, even when indoors</b>. Also, do not pick at spots to prevent further scarring...`,
      "ACNE_SCARS": `to help <b>acne scars heal</b>, keep your skin <b>well-moisturized</b> to prevent dryness and support repair. Also, avoid picking at your skin to prevent further scarring...`,
      "PIGMENTATION": `<b>sun exposure can worsen pigmentation</b> and slow treatment. Protect your skin - wear a hat or scarf outdoors and <b>don’t forget sunscreen!</b>`,
      "DULL_SKIN": `to <b>revive dull skin</b>, keep it well-moisturized to maintain <b>hydration and support natural renewal</b>. Avoid harsh scrubbing, as it can cause irritation...`,
      "UNDER_EYE_DARK_CIRCLES": `to <b>lighten dark circles</b>, keep the under-eye area <b>hydrated and gently massage</b> to boost circulation. Prioritize rest and avoid eye strain for brighter skin...`,
      "ANTI_AGING": `for <b>youthful skin</b>, keep your skin <b>well-moisturized to maintain elasticity</b> and a smooth texture. Also, avoid harsh elements and excessive rubbing...`,
      "NO_CONCERN": `your skin looks great! To help <b>maintain its natural glow</b>, we’ve crafted a simple skincare routine which will keep your skin <b>healthy and radiant</b>`
    }
  },
  hi: {
    pregnancy: {
      "Trying for pregnancy": `आपकी स्किन केयर <b>पूरी तरह सेफ है जब आप प्रेग्नेंसी की कोशिश</b> कर रही हैं!  खूब पानी पिएं, पौष्टिक खाना खाएं, और हेल्दी स्किन के लिए डेयरी और शुगर कम करें..`,
      "I am pregnant": `आपकी स्किनकेयर रूटीन <b>प्रेग्नेंसी के लिए पूरी तरह सुरक्षित</b> है! खूब पानी पिएं, पौष्टिक खाना खाएं, और हेल्दी स्किन के लिए डेयरी और शुगर कम करें..`,
      "I am a new mom and breastfeeding": `आपकी स्किनकेयर रूटीन <b>ब्रेस्टफीडिंग के दौरान सुरक्षित</b> है! खूब पानी पिएं, पौष्टिक खाना खाएं, और हेल्दी स्किन के लिए डेयरी और शुगर कम कर..`,
    },
    treatmentChange: (displayConcern: string, privateConcern: string) =>
      `<b>पहले आपके ${displayConcern} का इलाज ज़रूरी है</b>, फिर ${privateConcern} पर ध्यान देंगे। दिए गए निर्देश मानें, हाइड्रेटेड रहें और बेहतरीन रिजल्ट के लिए डेयरी और शुगर कम करें..`,
    pcod: `यह स्किनकेयर खास तौर पर <b>आपकी PCOD को ध्यान में रखकर बनाया गया</b> है और यह पूरी तरह सुरक्षित है। हाइड्रेटेड रहें और बेहतरीन रिजल्ट के लिए इसे नियमित रूप से इस्तेमाल करें..`,
    sensitiveSkin: `हमने आपकी <b>सेंसिटिव त्वचा के लिए सुरक्षित प्रोडक्ट</b> चुने हैं। तीखा खाना और गर्म पेय से बचें, क्योंकि ये सेंसिटिविटी बढ़ा सकते हैं..`,
    dryness: `त्वचा की <b>ड्राइनेस कम करने के लिए</b> हाइड्रेटेड रहें, ओमेगा-3 युक्त भोजन लें और जरूरत हो तो ह्यूमिडिफायर इस्तेमाल करें। साथ ही, त्वचा पर नरम रहें - हार्श स्क्रब और अल्कोहल-बेस्ड टोनर से बचें!`,
    skinConcern: {
      "ACNE_OR_PIMPLES": `दाग़ से बचने के लिए <b>मुहांसों को छूने या फोड़ने से बचें</b>। साथ ही, खूब पानी पिएं और बेहतरीन रिजल्ट के लिए डेयरी और शुगर का सेवन कम करें..`,
      "DARK_SPOTS_OR_MARK": `<b>सनस्क्रीन डार्क स्पॉट्स को गहरा होने से बचाता</b> है। इसलिए, <b>रोज़ाना सनस्क्रीन लगाएं</b>, चाहे आप घर के अंदर ही क्यों न हों। साथ ही, दाग़ से बचने के लिए स्पॉट्स को न छूएं..`,
      "ACNE_SCARS": `<b>एक्ने के निशान</b> जल्दी भरने के लिए त्वचा को <b>अच्छी तरह मॉइस्चराइज</b> करें ताकि ड्राइनेस न हो और रिपेयर में मदद मिले। साथ ही, ज्यादा दाग़ न पड़ें, इसके लिए त्वचा को छूने या खुजाने से बचें..`,
      "PIGMENTATION": `<b>धूप पिग्मेंटेशन बढ़ा सकती है</b> और ट्रीटमेंट को धीमा कर सकती है। अपनी त्वचा की सुरक्षा करें - बाहर जाते समय टोपी या दुपट्टा पहनें और <b>सनस्क्रीन लगाना न भूलें</b>!`,
      "DULL_SKIN": `<b>डल स्किन को फिर से निखारने</b> के लिए इसे <b>अच्छे से मॉइस्चराइज</b> करें ताकि नमी बनी रहे और त्वचा खुद को रिपेयर कर सके। ज्यादा स्क्रबिंग से बचें, क्योंकि यह जलन पैदा कर सकता है..`,
      "UNDER_EYE_DARK_CIRCLES": `<b>डार्क सर्कल कम</b> करने के लिए <b>अंडर-आई एरिया हाइड्रेटेड रखें</b> और हल्की मसाज करें ताकि ब्लड सर्कुलेशन बढ़े। भरपूर आराम करें और आंखों पर ज्यादा जोर देने से बचें..`,
      "ANTI_AGING": `<b>त्वचा को जवां बनाए</b> रखने के लिए इसे अच्छे से <b>मॉइस्चराइज करें ताकि लचीलापन और स्मूथ टेक्सचर</b> बना रहे। साथ ही, कठोर चीजों के संपर्क और ज्यादा रगड़ने से बचें..`,
      "NO_CONCERN": `आपकी त्वचा बेहतरीन दिख रही है! इसके नैचुरल <b>ग्लो को बनाए</b> रखने के लिए, हमने <b>एक आसान स्किनकेयर रूटीन</b> तैयार किया है, जो आपकी त्वचा को हेल्दी और चमकदार रखेगा..`
    }
  }
};

export const TREATMENT_MAPPING = {
  "ACNE_OR_PIMPLES": ["acne", "comedones", "inflammatory acne", "मुहांसे", "कॉमेडोन", "कॉमेडोन्स", "इन्फ्लेमेटरी एक्ने"],
  "DARK_SPOTS_OR_MARK": ["dark spots", "डार्क स्पॉट्स"],
  "ACNE_SCARS": ["acne scars", "एक्ने के निशान", 'एक्ने स्कार्स , माइल्ड इंफ्लेमेटरी एक्ने'],
  "PIGMENTATION": ["melasma", "pigmentation", "मेलास्मा","पिगमेंटेशन / झाइयां", "पिगमेंटेशन", "झाइयां"],
  "DULL_SKIN": ["dull skin", "रूख़ा बेजान त्वचा"],
  "UNDER_EYE_DARK_CIRCLES": ["dark circles", "काले घेरे"],
  "ANTI_AGING": ["anti aging", "एंटी एजिंग", "बुढ़ापा", 'एंटी एजिंग / झुर्रियाँ']
};

export const PRIVATE_MAIN_CONCERN_DISPLAY_NAME = {
  en: {
    "ACNE_OR_PIMPLES": "Acne",
    "DARK_SPOTS_OR_MARK": "Dark Spots",
    "ACNE_SCARS": "Acne Scars",
    "PIGMENTATION": "Pigmentation",
    "DULL_SKIN": "Dull Skin",
    "UNDER_EYE_DARK_CIRCLES": "Under Eye Dark Circles",
    "ANTI_AGING": "Anti Aging",
    "NO_CONCERN": "No Concern"
  },
  hi: {
    "ACNE_OR_PIMPLES": "मुँहासे",
    "DARK_SPOTS_OR_MARK": "दाग धब्बे",
    "ACNE_SCARS": "मुँहासों के निशान",
    "PIGMENTATION": "रंगदोष",
    "DULL_SKIN": "सुस्त त्वचा",
    "UNDER_EYE_DARK_CIRCLES": "आंखों के नीचे काले घेरे",
    "ANTI_AGING": "एंटी एजिंग",
    "NO_CONCERN": "कोई चिंता नहीं"
  }
};


