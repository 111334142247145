import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { MAX_SECONDARY_CONCERNS_LENGTH, MIN_SECONDARY_CONCERNS_LENGTH } from 'e2e/src/shared/constants';
import { AppConfig } from '../app.config';

@Component({
  selector: 'main-concern',
  templateUrl: './main-concern.html',
  standalone: false,
})
export class MainConcernComponent implements OnDestroy {
  @ViewChild('container', { static: false }) container: ElementRef;
  ui: { [key: string]: boolean } = { loading: true, saveInProcess: false };
  serverConcernList: Array<any> = [];
  searchEnabled: boolean = false;
  mainConcerns: Array<any> = [];
  mainConcernsCopy: Array<any> = [];
  classBasedConcerns: any = { FACE: [], BODY: [], HAIR: [] };
  concernSections: any = { CARE: [], TREATMENT: [] };
  searchInput: any;
  searchHash: any = {};
  concernClass: any;
  selectedConcerns: Array<any> = [];
  selectedConcernsDetails: Array<any> = [];
  latestConcernDescription: any;
  isCureSelected: boolean = false;
  newConcernNames: any = {};
  startTimer: number;
  showSecondaryConcerns: boolean = false;
  selectedSecondaryConcerns: any = {};
  isConcernClassHair: boolean = false;
  faceMultiConcernUI: boolean = false;
  hairMultiConcernUI: boolean = false;

  constructor(public conn: ConnectionService,
    protected router: Router,
    private localStorageService: LocalStorageService,
    public eventLogger: EventLoggerService,
    public activatedRoute: ActivatedRoute,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private readonly appWebBridge: AppWebBridgeService,) {}

  async ngOnInit(): Promise<any> {
    this.startTimer = new Date().getTime();
    this.ui = { loading: true };
    await this.conn.updateLocalUser();
    this.concernClass = this.activatedRoute.snapshot.queryParams.class || this.appConfig.Shared.Regimen.Class.FACE;
    this.isConcernClassHair = this.activatedRoute.snapshot.queryParams.class === this.appConfig.Shared.Regimen.Class.HAIR;

    if (this.concernClass === this.appConfig.Shared.Regimen.Class.FACE) {
      this.faceMultiConcernUI = true;
      this.eventLogger.cleverTapEvent('pageOpen',
        JSON.stringify({ pageName: 'Onboarding problem type selection screen - Primary concern' }));
    } else if (this.concernClass === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.hairMultiConcernUI = true;
    }

    await this.fetchConcerns();
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('concern_selection_page_loading_time', { timeInMillSec: time });
  }

  async fetchConcerns(): Promise<any> {
    this.ui.loading = true;
    if (!this.serverConcernList.length) {
      this.serverConcernList = await this.conn.findMainWithSecondaryConcerns({
        concernClass: this.concernClass,
        shouldTranslateConcernsList: true,
      });
    }
    this.ui.loading = false;
    this.mainConcerns = this.findTopMainConcerns();

    if (this.mainConcerns.length === 0) {
      this.eventLogger.trackInElasticSearch({
        event: 'MAIN_CONCERN_NOT_FOUND',
        type: 'ERROR_MAIN_CONCERN_NOT_FOUND',
        user: this.conn.getActingUser().get('username'),
      });
    }

    this.mainConcernsCopy = this.mainConcerns;
    this.onSelectionChange();
  }

  findTopMainConcerns(): Array<any> {
    const selectedMainConcerns = this.serverConcernList.sort((item1: any, item2: any): number => {
      const rankOfItem1 = item1.rank || 999999;
      const rankOfItem2 = item2.rank || 999999;
      return rankOfItem1 - rankOfItem2;
    });

    const finalResult: Array<any> = [];

    if (this.faceMultiConcernUI || this.hairMultiConcernUI) {
      selectedMainConcerns.forEach((concern: any): void => {
        finalResult.push({
          value: concern.value,
          key: concern.displayName,
          keyInEnglish: concern.keyInEnglish || '',
          class: concern.class,
          rank: concern.rank,
          description: concern.description || '',
          section: concern.section,
          concernsList: concern?.concernsList || [],
        });
      });
    } else {
      selectedMainConcerns.forEach((concern: any): void => {
        concern.keywords.forEach((item: any): void => {
          finalResult.push({
            value: concern.value,
            key: item.key,
            keyInEnglish: item.keyInEnglish || '',
            class: concern.class,
            rank: concern.rank,
            description: item.description || concern.description || '',
            section: concern.section,
          });
        });
      });
    }

    return finalResult;
  }

  addRemoveProblem(problem: any): void {
    // check if problem already selected
    const isProblemAlreadySelected = this.selectedConcernsDetails.some(
      (concern: any): boolean => concern.key === problem.key && concern.value === problem.value,
    );
    if (isProblemAlreadySelected) {
      // remove the problem if it's already selected
      this.selectedConcerns = this.selectedConcerns.filter((key: string): boolean => key !== problem.key);
      this.selectedConcernsDetails = this.selectedConcernsDetails.filter(
        (concern: any): boolean => concern.key !== problem.key,
      );
      this.latestConcernDescription = this.selectedConcernsDetails.length > 0
        ? this.selectedConcernsDetails[this.selectedConcernsDetails.length - 1].description
        : undefined;
    } else {
      // replace existing values with the new problem
      this.selectedConcerns = [problem.key];
      this.selectedConcernsDetails = [problem];
      this.latestConcernDescription = problem.description;
    }
  }

  onSelectionChange(): void {
    const searchKey = this.searchInput || '';
    if (!searchKey) {
      this.selectedConcerns = [];
      this.selectedConcernsDetails = [];
      delete this.latestConcernDescription;
    }
    this.mainConcernsCopy = this.mainConcerns.filter((each: any): void => (
      each.key.toLowerCase().includes(searchKey.toLowerCase())
      || each.value.toLowerCase().includes(searchKey.toLowerCase())
      || each.keyInEnglish.toLowerCase().includes(searchKey.toLowerCase())
      || each.value.toLowerCase().includes('something')
      || each.keyInEnglish.toLowerCase().includes('something')));
    this.logSearch();
    this.classBasedConcerns[this.concernClass] = this.mainConcernsCopy.filter((each: any): boolean => each.class === this.concernClass);
    this.concernSections.CARE = this.classBasedConcerns[this.concernClass].filter((each: any): boolean => each.section === 'CARE');
    this.concernSections.TREATMENT = this.classBasedConcerns[this.concernClass]
      .filter((each: any): boolean => each.section === 'TREATMENT');
    if (!searchKey.length && !this.searchEnabled) {
      this.concernSections.CARE = this.concernSections.CARE.slice(0, 8);
      this.concernSections.TREATMENT = this.concernSections.TREATMENT.slice(0, 8);
    }
  }

  async onMainConcernSave(): Promise<any> {
    this.ui.saveInProcess = true;
    const concerns = Array.from(this.selectedConcernsDetails)
      .sort((value1: any, value2: any): number => value1.rank - value2.rank)
      .map((each: any): void => each.value);
    const userSelectedConcern = Array.from(this.selectedConcernsDetails)
      .sort((value1: any, value2: any): number => value1.rank - value2.rank)
      .map((each: any): void => each.key);
    this.eventLogger.cleverTapEvent('concernSelected', JSON.stringify({ name: userSelectedConcern }));
    this.eventLogger.trackEvent('main_concern_selected', { concerns, userSelectedConcern });
    this.conn.updateExperimentLogsInUser({ main_concern_selected: true });
    try {
      await this.conn.saveUserMainConcern(concerns);
      this.localStorageService.delete('Onboarding/PrivateMainConcernClass');
      this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
        branch: { name: 'mainConcernUpdated' },
        firebase: { name: 'mainConcernUpdated' },
      });

      if (this.faceMultiConcernUI && this.isSecondaryConditionSelected) {
        const secondaryConcernsSet = Object.values(this.selectedSecondaryConcerns).reduce(
          (acc: Set<any>, curVal: any): Set<string> => {
            acc.add(curVal.value);
            return acc;
          }, new Set());
        const secondaryConcernsArray = Array.from(secondaryConcernsSet as Set<any>);
        await this.conn.saveUserSecondaryConcerns(secondaryConcernsArray);
        const consultationSession = await this.conn.findConsultationSession(this.appConfig.Shared.Regimen.Class.FACE);
        await consultationSession.set('concernList', secondaryConcernsArray);
        await consultationSession.save();
      }
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', { message: err.toString() });
    }
    if (this.activatedRoute.snapshot.queryParams.instantCheckupId) {
      await this.router.navigate([`/user/instantCheckup/${this.activatedRoute.snapshot.queryParams.instantCheckupId}`]);
    } else {
      const [session]: Array<any> = await this.conn.fetchConsultationSessions({
        where: { user: this.conn.getActingUser(), PrivateMainConcern: concerns[0], archive: false },
      });
      const queryParams: Record<string, string> = { type: 'consultationSession', username: this.conn.getActingUser().get('username') };
      if (this.activatedRoute.snapshot.queryParams.redirectOnBack) {
        queryParams.redirectOnBack = this.activatedRoute.snapshot.queryParams.redirectOnBack;
      }
      this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
        branch: { name: 'consultationSessionStarted' },
        firebase: { name: 'consultationSessionStarted' },
      });
      await this.router.navigate([`/chatV2/${session?.id}`], { queryParams });
    }
    this.ui = { loading: false, saveInProcess: false };
  }

  enableSearch(): void {
    this.container.nativeElement.scrollTop = 0;
    this.searchEnabled = true;
    this.onSelectionChange();
  }

  logSearch(): any {
    if (!this.mainConcernsCopy.length && this.searchInput && !this.searchHash[this.searchInput]) {
      this.searchHash[this.searchInput] = 1;
      this.eventLogger.trackInElasticSearch({
        event: 'MAIN_CONCERN_SEARCH',
        type: 'APP_SEARCH_LOG',
        key: this.searchInput,
        user: this.conn.getActingUser().get('username'),
      });
    }
  }

  onNewFlowClick(flow: any): void {
    this.isCureSelected = flow === 'cure';
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: flow }));
    const problem = {
      class: 'FACE',
      description: 'Get a skin improvement kit made just for you',
      key: 'Better, Healthier skin',
      rank: 1,
      section: 'CARE',
      value: 'NO_CONCERN',
    };
    this.selectedConcerns.push(problem.key);
    this.selectedConcernsDetails.push(problem);
    this.latestConcernDescription = problem.description;
    this.onMainConcernSave();
  }

  openTab(url: string): void {
    this.conn.navigateToURL(url);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
    this.eventLogger.cleverTapEvent('clickedBack', JSON.stringify({ pageName: 'concern-selection' }));
  }

  handleAddPrimaryConcern(problem: any): void {
    if (!this.ui.saveInProcess) {
      this.addRemoveProblem(problem);
    }
  }

  toggleShowSecondaryConcerns(): void {
    this.showSecondaryConcerns = !this.showSecondaryConcerns;
  }

  handleShowSecondaryConcernsScreen(): void {
    if (!this.ui.saveInProcess) {
      if (this.selectedConcernsDetails?.[0]?.concernsList?.length) {
        this.toggleShowSecondaryConcerns();
        this.eventLogger.cleverTapEvent('pageOpen',
          JSON.stringify({ pageName: 'Onboarding problem type selection screen - Other concerns' }));
      } else {
        this.onMainConcernSave();
      }
    }
  }

  handleSkipPrimaryConcern(): void {
    this.addRemoveProblem(this.concernSections.CARE[0]);
    this.onMainConcernSave();
  }

  handleBackToPrimaryConcerns(): void {
    if (!this.ui.saveInProcess) {
      this.selectedSecondaryConcerns = {};
      this.toggleShowSecondaryConcerns();
    }
  }

  addRemoveSecondaryConcern(concern: any): void {
    if (this.ui.saveInProcess) return;
    if (this.selectedSecondaryConcerns[concern.displayName]) {
      delete this.selectedSecondaryConcerns[concern.displayName];
    } else if (Object.keys(this.selectedSecondaryConcerns).length < 3) {
      this.selectedSecondaryConcerns[concern.displayName] = concern;
    }
  }

  get isSecondaryConditionSelected(): boolean {
    return Object.keys(this.selectedSecondaryConcerns).length >= MIN_SECONDARY_CONCERNS_LENGTH;
  }

  get isOpacityConditionMet(): boolean {
    return Object.keys(this.selectedSecondaryConcerns).length >= MAX_SECONDARY_CONCERNS_LENGTH;
  }

  handleContinueSecondaryConcern(): void {
    this.onMainConcernSave();
  }

  handleSkipSecondaryConcern(): void {
    this.selectedSecondaryConcerns = {};
    this.onMainConcernSave();
  }

  ngOnDestroy(): void { }
}
