<div class="tw-bg-white tw-px-4 tw-rounded-2 tw-shadow-z4">
  <div class="tw-flex tw-items-center tw-gap-2 tw-py-[18px] tw-pl-2">
    <div>
      <img class="tw-w-11 tw-h-11 tw-rounded-full" [src]="doctorDisplayImage" alt="doctorImage">
    </div>
    <div>
      <h3 class="tw-text-300 tw-uppercase tw-text-gray-800" style="font-weight: 700 !important" i18n="@@recommended">Recommended</h3>
      <p class="tw-text-300 tw-uppercase tw-font-body tw-text-gray-800" i18n="@@ingredientsForYourSkin">Ingredients for your skin</p>
    </div>
  </div>
  <div class="tw-w-[90%] tw-mx-auto tw-h-[1px] tw-bg-gray-200"></div>
  <div class="tw-w-full tw-max-w-[318px] tw-grid tw-grid-cols-2 tw-justify-items-center tw-gap-y-8 tw-mt-4 tw-mb-7 tw-mx-auto xxs:tw-grid-cols-3">
    <div *ngFor="let ingredient of heroIngredients" class="tw-w-[100px] tw-text-center">
      <img [src]="ingredient.image" alt="ingredient" class="tw-mx-auto">
      <p class="tw-text-200 tw-text-gray-800 tw-font-heading tw-mt-2" style="font-weight: 700 !important">{{ingredient.name}}</p>
      <p class="tw-text-200 tw-text-gray-500 tw-font-body tw-font-normal tw-text-center">{{ingredient.description}}</p>
    </div>
  </div>
  <div class="tw-mx-auto tw-h-[1px] tw-bg-gray-200"></div>
  <div class="tw-pl-0.5 tw-pt-[18px] tw-pr-[22px] tw-pb-8">
    <p class="tw-text-200 tw-font-medium tw-font-body tw-text-teal-700" i18n="@@ingredientsWillWorkTogether">These ingredients will work together to address your skin concerns and improve overall skin health</p>
  </div>
</div>